
import { Navigate } from 'react-router-dom';
import { auth_service } from '../../authService';
import { isWebView } from '../../../utils/Common';

export const PrivateRoute = ({ element, pathName }) => {
    if(isWebView()){
        return element;
    }
    if (!auth_service.isAuthenticated()) {
        if(pathName) {
            auth_service.setRedirectionUrl(pathName);
        }
        return <Navigate to="/login" />
    }
    return element;
};

export const PublicRoute = ({ element }) => {
    return element;
};

export const ProtectedRoute = ({ element }) => {
    if(isWebView()){
        return element;
    }
    const userDetails = auth_service.getUserDetails();
    const currentUserData = auth_service.getCurrentUserData();
    if (!auth_service.isAuthenticated()) {
        return <Navigate to="/login" />
    }
    if (((userDetails?.type === "INSTITUTE" || currentUserData?.type === "INSTITUTE") && element?.props?.instituteAccess) || ((userDetails?.type === "CANDIDATE" || currentUserData?.type === "CANDIDATE") && element?.props?.candidateAccess) || (userDetails?.type === "INDIVIDUAL" && !currentUserData?.type && element?.props?.individualAccess) || (userDetails?.type === "INDIVIDUAL" && currentUserData?.userType === "FACULTY" && element?.props?.facultyAccess)) {
        return element;
    }
    return <Navigate to={`/${userDetails?.type === "CANDIDATE" ? "student" : userDetails?.type === "INSTITUTE" ? "institution" : "profile"}/${userDetails?.customUrl}`} />;
};

