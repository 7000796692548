const API_URL = process.env.REACT_APP_authServiceURL;
const ML_URL = process.env.REACT_APP_recommendServiceURL;


const Apis = {

    // Python  api uses
    MLPostUserUpdate: `${ML_URL}/users/update/`,
    MLGetFeturedNews: (data) => `${ML_URL}/users/trending/?userId=${data?.userId}&page=${data?.page}&size=${data?.size}`,
    MLGetPublication: (data) => `${ML_URL}/recommended/publication-category?userId=${data?.userId}`,
    MLGetPublicationWiseData: (data) => `${ML_URL}/recommended/publication-wise-news/?userId=${data?.userId}&publication=${data?.publication}&page=${data?.page}&size=${data?.size}`,
    MLGetAllCategory: `${ML_URL}/recommended/news-category`,
    MLUpdateFilterData: `${ML_URL}/users/apply-filter/`,
    MLUpdateViewData: `${ML_URL}/users/article-view/`,
    MLRecentReads: (data) => `${ML_URL}/users/recent-reads/?userId=${data.userId}&page=${data?.page}&size=${data?.size}`,
    MLRecomendedNews: (data) => `${ML_URL}/recommended/news/?userId=${data.userId}&page=${data?.page}&size=${data?.size}`,
    MLRecomendedSearch: `${ML_URL}/recommended/search`,
    MLGetSavedItem: `${ML_URL}/users/user-bookmark-details`,
    MLGetSavedPublication: `${ML_URL}/users/user-saved-publication/`,
    MLPostSaveItem: `${ML_URL}/users/user-bookmark/`,
    MLLatestNews: (data) => `${ML_URL}/users/latest/?userId=${data?.userId}&page=${data?.page}&size=${data?.size}`,
    MLSuggestedSearch: `${ML_URL}/recommended/suggested-tag/`,
    MLPostTopic: `${ML_URL}/users/recent-search/`,
    MLGetTopic: `${ML_URL}/users/get-recent-search/`,
    MLGetNewsBySlug: (data) => `${ML_URL}/users/get-article-by-slug?userId=${data?.userId}&slug=${data?.slug}`,

    /*ML Filter APis */
    GetCategoryListUserWise: (data) => `${ML_URL}/recommended/news-category/?userId=${data?.userId}`,
    GetTrendingFilterDataList: (data) => `${ML_URL}/recommended/trending-category/?userId=${data?.userId}`,
    MLGetPublicationFilter: (data) => `${ML_URL}/recommended/publication-category/?userId=${data?.userId}&eventType=${data?.eventType}`,
    MLGetFilterCount: (data) => `${ML_URL}/recommended/user-filter-count/?userId=${data?.userId}`,
    MLGlobalResetFilter: `${ML_URL}/users/reset-filters/`,


    /* ML- Quiz-Apis */
    GetQuizDataUserwise: (data) => `${ML_URL}/users/userwise-quiz?userId=${data?.userId}&date=${data.date}&page=${data?.page}&size=${data?.size}&sessionId=${data.sessionId}`,
    GetReadAndUnreadQuizListByUser: `${ML_URL}/users/attempt-quiz`,
    SubmitSelectedQuiz: `${ML_URL}/users/start-quiz`,
    SubmitUserAttamtedQuizData: `${ML_URL}/users/quiz-submission`,
    CheckQuizEligibility: (data) => `${ML_URL}/users/quiz-eligibilty?userId=${data.userId}&date=${data.date}`,
    GetReviewQuizList: (data) => `${ML_URL}/users/review?sessionId=${data.sessionId}`,
    GetQuizArticleScrappingStaus: `${ML_URL}/users/quizarticle-scrapping-status`,
    
    /* Saved Article API */
    UpdateSaveArticleItem: `${ML_URL}/users/user-bookmark/`,
    
    //Institute API
    InstituteLogin: `${API_URL}/institute/login`,
    InstituteRegister: `${API_URL}/institute/signup`,
    GetInstituteDataByCustomUrl: (customUrl) => `${API_URL}/institute/get-by-customurl?customUrl=${customUrl}`,
    UpdateUserProfile: `${API_URL}/institute/update`,
    CreateUserTaxinformationData: `${API_URL}/tax-information/save`,
    TaxInformationData: (id) => `${API_URL}/tax-information/get?userId=${id}`,
    UploadAttachment: `${API_URL}/backend/file/uploadAttachment`,
    UpdateUserTaxinformationData: `${API_URL}/tax-information/update`,
    CheckAlreadyVerifyInstitute: (licenseCode) => `${API_URL}/institute/signup-verify?licenseCode=${licenseCode}`,
    GetautoFetchAddressByGstNo: (gstNumber) => `${API_URL}/gst/validate/get?gstin=${gstNumber}`,
    GetBoardList: `${API_URL}/board/list`,
    GetClassListForQuizAnalytics: (instituteId) => `${API_URL}/institute-class/class-list?instituteId=${instituteId}`,
    GetInstituteDetailsById: (instituteId) => `${API_URL}/institute/getById?id=${instituteId}`,

    //User API
    UserLogout: `${API_URL}/user/logout`,
    UserRenewToken: `${API_URL}/user/renew/token`,
    OtpVerify: `${API_URL}/otp/verify`,
    ChangePassword: (data) => `${API_URL}/${data?.type === "individual" ? "individual" : data?.type === "institution" ? data?.userType !== "FACULTY" ? "institute" : "faculty" : "user"}/change_password`,
    ChangePasswordOtpResend: (transactionId) => `${API_URL}/otp/password_change_otp_re_send?transactionId=${transactionId}`,
    CookieAcceptance: `${API_URL}/user/acceptCookies`,
    GetLicenseCodeByEmail: (email) => `${API_URL}/institute/fetch-code?email=${email}`,
    UserCodeStatusData: (data) => `${API_URL}/institute/get-code-status?email=${data?.email}&code=${data?.code}`,
    GetNewsepickIdByCandidateId: (data) => `${API_URL}/user/fetch-newspickId?candidateId=${data?.candidateId}&instituteId=${data?.instituteId}`,
    GetUserDetailsByEmail: (email) => `${API_URL}/user/user-detail-by-email?email=${email}`,
    GetUserDetailsById: (id) => `${API_URL}/user/get-by-userId?userId=${id}`,
    UpdateDefaultProfile: (userId) => `${API_URL}/user/update_default_profile?userId=${userId}`,

    SetInitializeUserFilter : `${ML_URL}/recommended/user-initialize-filter/`,

    //Location API
    GetCountryData: `${API_URL}/backend/region/countries`,
    GetStatesData: (countryName) => `${API_URL}/backend/region/states/${countryName}`,
    GetCitiesData: (stateName) => `${API_URL}/backend/region/cities/${stateName}`,

    //Student API
    AddSingleStudentUpload: `${API_URL}/user/account/create`,
    AddBulkStudentUpload: `${API_URL}/user/bulk/upload`,
    GetBulkUploadHistoryData: (referenceId) => `${API_URL}/bulk/upload/history/data?referenceId=${referenceId}`,
    GetBulkUserListData: (data) => `${API_URL}/bulk/upload/history/user/list?referenceId=${data?.referenceId}&timeStamp=${data?.timeStamp}`,
    GetCandidateListData: (data) => `${API_URL}/user/list?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}&searchType=${data?.searchType}&searchQuery=${data?.searchQuery}&page=${data?.page}&size=${data?.size}&userStatus=${data?.userStatus}`,
    CandidateLogin: `${API_URL}/user/login`,
    GetStudentDataByCustomUrl: (customUrl) => `${API_URL}/user/get-by-customurl?customUrl=${customUrl}`,
    UpdateStudentProfile: `${API_URL}/user/update-by-customurl`,
    GetUserDataByCustomUrl: (data) => `${API_URL}/${data?.type === "INSTITUTE" ? (data?.userType !== "FACULTY" ? "institute" : "faculty") : data?.type === "CANDIDATE" ? "user" : "individual"}/get-by-customurl?customUrl=${data?.customUrl}`,
    GetStudentInstituteList: `${API_URL}/institute/active`,
    UpdateCandidate: `${API_URL}/user/update-candidate`,
    RemoveCandidate: `${API_URL}/user/remove`,
    getCandidateDataByNewsePickId: (data) => `${API_URL}/user/candidate-detail?instituteId=${data?.instituteId}&newsEpickId=${data?.newsEpickId}`,
    CandidateDetailsByNewsEpickId: (newsEpickId) => `${API_URL}/user/candidate-detail-by-newsepickId?newsEpickId=${newsEpickId}`,
    GetCandidateStatusCont: (data) => `${API_URL}/user/present-left-count?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}`,

    //Institute Category API
    GetCategoryList: `${API_URL}/institute-class/config-categories`,
    GetClassConfigList: (instituteId) => `${API_URL}/institute-class/config-class?instituteId=${instituteId}`,
    GetCategoryConfigList: (data) => `${API_URL}/institute-class/config-news?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}`,
    GetAllClassCategoriesList: (instituteId) => `${API_URL}/institute-class/get-all-class-categories?instituteId=${instituteId}`,
    SaveClassCategory: `${API_URL}/institute-class/save-category`,
    UpdateClassCategory: `${API_URL}/institute-class/update-category`,
    DeleteClassCategory: (data) => `${API_URL}/institute-class/delete?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}`,

    //Individual API 
    IndividualUserRegister: `${API_URL}/individual/signup`,
    IndividualLogin: `${API_URL}/individual/login`,
    IndividualProfileUpdate: `${API_URL}/individual/update`,
    IndividualOtpVerify: `${API_URL}/otp/individual/signup`,
    IndividualOtpResend: (transactionId) => `${API_URL}/otp/individual_signup_otp_resend?transactionId=${transactionId}`,
    IndividualChangePasswordOtpResend: (transactionId) => `${API_URL}/otp/individual_password_change_otp_re_send?transactionId=${transactionId}`,

    PasswordChangeOtp: (data) => `${API_URL}/otp/${data?.type === "institution" ? data?.userType === "FACULTY" ? "faculty_password_change_otp_send" : "password_change_otp_send_institute" : data?.type === "individual" ? "individual_password_change_otp_send" : "student_password_change_otp_send"}?email=${data?.email}`,
    UserChangePasswordOtpResend: (transactionId) => `${API_URL}/otp/student_password_change_otp_re_send?transactionId=${transactionId}`,

    //Intitute Admin API
    CreateInstituteAdmin: `${API_URL}/institute/admin/add`,
    RemoveInstituteAdmin: `${API_URL}/institute/admin/remove`,
    GetInstituteAdminList: (instituteId) => `${API_URL}/institute/admin/list?instituteId=${instituteId}`,
    UpdateInstituteStatusByUserType: (userType) => `${API_URL}/${userType === "ADMIN" ? "institute/admin" : "faculty"}/change/status`,
    GetInstituteStatusByUserType: (data) => `${API_URL}/${data?.userType === "ADMIN" ? "institute/admin/get-status" : "faculty/get-status"}?instituteId=${data?.instituteId}&email=${data?.email}`,
    GetInstituteAdminData: (data) => `${API_URL}/institute/admin/getById?instituteId=${data?.instituteId}&id=${data?.id}`,
    UpdateInstituteAssignmentPrefix: `${API_URL}/institute/update-assignment-prefix`,
    GetInstituteAssignmentPrefixCode: (id) => `${API_URL}/institute/get-assignment-prefix?instituteId=${id}`,
    GetInstituteSubscriptionConfigData: (id) => `${API_URL}/subscription/config/institute/data?instituteId=${id}`,
    GetInstituteFaceRecognitionStatus: (instituteId) => `${API_URL}/subscription/institute/face-recognition-status?instituteId=${instituteId}`,

    //Analytics API
    GetInstituteAnalyticsCategorywise: (data) => `${ML_URL}/users/institute-view-impression?instituteId=${data?.instituteId}&classString=${data?.classString}&dateString=${data?.dateString}&analyticsViewOption=${data?.analyticsViewOption}&page=${data?.page}&size=${data?.size}`,
    GetInstituteAnalyticsConsumptionDuringDays: (data) => `${ML_URL}/users/timewise-consumption?instituteId=${data?.instituteId}&dateString=${data?.dateString}&page=${data?.page}&size=${data?.size}`,
    GetInstituteCompareAnalyticsData: (data) => `${ML_URL}/users/compare-class-entity?instituteId=${data?.instituteId}&dateString=${data?.dateString}&classString=${data?.classString}&entity=${data?.entity}&page=${data?.page}&size=${data?.size}`,
    GetCandidateAnalyticsCategorywise: (data) => `${ML_URL}/users/student-view-impression?studentId=${data?.studentId}&dateString=${data?.dateString}&analyticsViewOption=${data?.analyticsViewOption}&page=${data?.page}&size=${data?.size}`,
    GetCandidateCompareAnalyticsData: (data) => `${ML_URL}/users/student-entity-comparison?studentId=${data?.studentId}&dateString=${data?.dateString}&entity=${data?.entity}&page=${data?.page}&size=${data?.size}`,
    GetInstituteAnalyticsQuizCategorywise: (data) => `${ML_URL}/users/inst-quiz-class-categorywise-attempts?instituteId=${data?.instituteId}&classString=${data?.classString}&dateString=${data?.dateString}&page=${data?.page}&size=${data?.size}&studentId=${data?.studentId}`,
    GetCandidateAnalyticsQuizCategorywise: (data) => `${ML_URL}/users/student-quiz-categorywise-attempts?studentId=${data?.studentId}&dateString=${data?.dateString}&page=${data?.page}&size=${data?.size}`,

    //Transaction API
    GetTransactionDataByUserType: (data) => `${API_URL}/transaction/list?userId=${data?.userId}&creditType=${data?.creditType}&userType=${data?.userType}&page=${data?.page}&size=${data?.size}`,

    //Institute Quiz Summary Apis

    GetQuizSummaryData: (data) => `${API_URL}/quiz/summary/list?instituteId=${data?.instituteId}&filter=${data?.filter}&year=${data?.year}&page=${data?.page}&size=${data?.size}`,
    GetQuizSummaryClassWise: (data) => `${API_URL}/quiz/summary/classwise/list?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}&filter=${data?.filter}&year=${data?.year}&searchType=${data?.searchType}&searchQuery=${data?.searchQuery}&page=${data?.page}&size=${data?.size}`,
    GetQuizSummaryByUserId: (data) => `${API_URL}/quiz/summary/classwise/student/list?instituteId=${data?.instituteId}&userId=${data?.userId}&studentClass=${data?.studentClass}&filter=${data?.filter}&page=${data?.page}&size=${data?.size}`,
    GetQuizSummaryCandidateData: (data) => `${API_URL}/quiz/summary/student/list?userId=${data?.userId}&filter=${data?.filter}&page=${data?.page}&size=${data?.size}`,

    //Faculty Api

    GetSubjectsListDataByBoardId: (boardId) => `${API_URL}/board/getById?boardId=${boardId}`,
    CreateFaculty: `${API_URL}/faculty/create`,
    UpdateFaculty: `${API_URL}/faculty/update-data`,
    UpdateFacultyProfile: `${API_URL}/faculty/update`,
    GetFacultyList: (data) => `${API_URL}/faculty/list?searchType=${data?.searchType}&searchQuery=${data?.searchQuery}&instituteId=${data?.instituteId}&page=${data?.page}&size=${data?.size}`,
    FacultyLogin: `${API_URL}/faculty/login`,
    GetFacultyData: (data) => `${API_URL}/faculty/get?id=${data?.id}`,
    ChangePasswordOtpResendFaculty: (transactionId) => `${API_URL}/otp/faculty_password_change_otp_re_send?transactionId=${transactionId}`,
    RemoveFaculty: `${API_URL}/faculty/remove`,
    GetFacultyHistoryList: (data) => `${API_URL}/faculty/history/list?facultyId=${data?.facultyId}&page=${data?.page}&size=${data?.size}`,

    //Curriculam API
    CreateCurriculam: `${API_URL}/curriculum/create`,
    GetCurriculam: (instituteId) => `${API_URL}/curriculum/get?instituteId=${instituteId}`,
    GetCurriculamSubjectList: (data) => `${API_URL}/curriculum/get/subjects?instituteId=${data?.instituteId}&boardId=${data?.boardId}&studentClass=${data?.studentClass}`,
    CreateCurriculamChapter: `${API_URL}/chapter/add`,
    UpdateCurriculamChapter: `${API_URL}/chapter/update`,
    DeleteCurriculamChapter: `${API_URL}/chapter/delete`,
    GetCurriculamChapter: (data) => `${API_URL}/chapter/get?subjectId=${data?.subjectId}&curriculumId=${data?.curriculumId}`,
    GetCurriculamClassList: (boardId) => `${API_URL}/curriculum/class/list?boardId=${boardId}`,
    GetChapterListSubjectWise: (data) => `${API_URL}/chapter/get/chapter_subjectwise?subjectId=${data?.subjectId}&curriculumId=${data?.curriculumId}`,

    GetBoardClassList: (boardId) => `${API_URL}/board/class/subject/available_list?boardId=${boardId}`,
    GetBoardClassSubjectList: (data) => `${API_URL}/board/class/subject/list?boardId=${data.boardId}&studentClass=${data?.studentClass}`,
    GetBoardClassSubjectChapterList: (data) => `${API_URL}/board/class/subject/chapter/list?boardId=${data.boardId}&studentClass=${data?.studentClass}&subjectId=${data?.subjectId}`,
    CreateBoardClassSubjectChapter: `${API_URL}/board/class/subject/chapter/save`,
    GetCurriculumIdByClass: (data) => `${API_URL}/curriculum/getId?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}`,

    //Assignment API
    CreateAssignment: `${API_URL}/assignment/create`,
    UpdateAssignment: `${API_URL}/assignment/update`,
    GetAssignmentList: (data) => `${API_URL}/assignment/list?instituteId=${data?.instituteId}&facultyId=${data?.facultyId}&studentClass=${data?.studentClass}&subjectId=${data?.subjectId}&page=${data?.page}&size=${data?.size}`,
    GetAssignmentDetailsById: (data) => `${API_URL}/assignment/question_list?assignmentId=${data?.assignmentId}&userId=${data?.userId}`,
    GetStudentAssignmentList: (data) => `${API_URL}/assignment${data?.candidateStatus === "INACTIVE" ? "/inactive" : ""}/student/list?instituteId=${data?.instituteId}&userId=${data?.userId}&subjectId=${data?.subjectId}&page=${data?.page}&size=${data?.size}`,
    GetInstituteAssignmentList: (data) => `${API_URL}/assignment/institute/list?instituteId=${data?.instituteId}&studentClass=${data?.studentClass}&subjectId=${data?.subjectId}&facultyName=${data?.facultyName}&page=${data?.page}&size=${data?.size}`,
    GetInstituteStudentAssignmentList: (data) => `${API_URL}/assignment/institute/student/list?instituteId=${data?.instituteId}&username=${data?.username}&page=${data?.page}&size=${data?.size}`,
    GetAssignmentQuestionDataById: (assignmentId) => `${API_URL}/assignment/question_list_for_student?assignmentId=${assignmentId}`,
    AssignmentInitiateRequest: `${API_URL}/assignment/submit/initiate`,
    AssignmentSubmitRequest: `${API_URL}/assignment/submit/save`,
    AssignmentQuestionUpdate: `${API_URL}/assignment/update-question`,
    AssignmentCompleteQuestionList: (data) => `${API_URL}/assignment/completed_question_list_for_student?assignmentId=${data?.assignmentId}&userId=${data?.userId}`,
    DraftAssignmentSave: `${API_URL}/draft/assignment/create`,
    GetDraftAssignmentById: (data) => `${API_URL}/draft/get?id=${data?.assignmentId}&userId=${data?.userId}`,
    GetCopyAssignmentDetailsById: (data) => `${API_URL}/assignment/copy/data?assignmentId=${data?.assignmentId}&userId=${data?.userId}`,
    GetInstituteAssignmentCount: (instituteId) => `${API_URL}/assignment/institute/count?instituteId=${instituteId}`,
    GetInstituteAssignmentMonthlyCount: (data) => `${API_URL}/assignment/institute/count/monthly?studentClass=${data?.studentClass}&instituteId=${data?.instituteId}&year=${data?.year}`,
    GetInstituteAssignmentSubjectCount: (data) => `${API_URL}/assignment/institute/count/subject?studentClass=${data?.studentClass}&instituteId=${data?.instituteId}`,
    GetInstituteMonthlyAssignmentList: (data) => `${API_URL}/assignment/list_for_institute/monthly?studentClass=${data?.studentClass}&instituteId=${data?.instituteId}&month=${data?.month}&year=${data?.year}&page=${data?.page}&size=${data?.size}`,
    GetInstituteSubjectAssignmentList: (data) => `${API_URL}/assignment/list_for_institute/subject?studentClass=${data?.studentClass}&instituteId=${data?.instituteId}&subjectId=${data?.subjectId}&year=${data?.year}&page=${data?.page}&size=${data?.size}`,
    GetAssignmentWiseStudentList: (data) => `${API_URL}/assignment/student/list_assignment_wise?instituteId=${data?.instituteId}&assignmentId=${data?.assignmentId}&studentClass=${data?.studentClass}&username=${data?.username}&page=${data?.page}&size=${data?.size}`,
    GetUserAssignmentAnalyticsOverViewData: `${ML_URL}/users/subjectwise-performance-overview`,
    GetCandidateAssignmentAnalyticsOverViewData: `${ML_URL}/users/student-subjectwise-performance-overview`,
    GetUserAssignmentAnalyticsDetails:  `${ML_URL}/users/subjectwise-performance-details`,
    GetCandidateAssignmentAnalyticsDetails: `${ML_URL}/users/student-subjectwise-performance-details`,
    DeleteAssignmentQuestion: (data) => `${API_URL}/assignment/${data?.type === "DRAFT" ? "delete-draft-question" : "delete-question"}?id=${data?.questionId}`,
    GetIndividualConfig: (userId) => `${API_URL}/individual/get-associated-subject?userId=${userId}`,
    GetFacultyConfigFromHistory: (data) => `${API_URL}/faculty/history/list?facultyId=${data?.facultyId}&page=${data?.page}&size=${data?.size}`,
    AssignmentSnapShortsData: `${ML_URL}/users/user-authentication-details`,
    GetSnapsReviewList: (data) => `${ML_URL}/users/user-authentication-review?userId=${data?.userId}&assignmentId=${data?.assignmentId}`,
    SubmitSnapsFacultyApproval: `${API_URL}/assignment/submit/update/face-recognition`,
    GetAssingmentUserData: (data) => `${ML_URL}/users/${data?.userType === "FACULTY" ? "faculty" : "student"}-list?${data?.userType === "FACULTY" ? "instituteId" : "userId"}=${data?.id}&classes=${data?.classes}&sections=${data?.sections}&page=${data?.page}&size=${data?.size}&search=${data?.search}&institutionId=${data?.institutionId}`,
    GetAssignmentAutoRegistrationConfig: (instituteId) => `${API_URL}/institute/get-auto-registration?instituteId=${instituteId}`,
    UpdateAssignmentCandidateConfig: `${API_URL}/assignment/update-candidate-config`,
    GetRecommendedAssignment: (data) => `${API_URL}/assignment/get_recommended?instituteId=${data?.instituteId}&userId=${data?.userId}`,

    //Subscription API
    CreatePayment: `${API_URL}/payment/create_order`,
    PaymentUpdate: `${API_URL}/payment/update_payment`,
    WalletPaymentUpdate: `${API_URL}/payment/update_wallet`,
    SubscriptionConfigdata: (userId) => `${API_URL}/subscription/config/data?userId=${userId}`,

    //faq categories top navigation menu api
    GetTopCategoriesData: (data) => `${API_URL}/faq_category/category-with-active-question`,

    /* EmailAnd Phnonumber verification  apis */
    VerifyStudentEmail: `${API_URL}/otp/student_email_verify_otp_send`,
    VerifyInstitutePhoneNumber:  `${API_URL}/otp/institute_phone_number_verify_otp_send`,
    VerifyIndividualPhoneNumber: `${API_URL}/otp/individual_phone_number_verify_otp_send`,
    ResendStudentEmailVerifyOtp: (transactionId) => `${API_URL}/otp/student_email_verify_otp_re_send?transactionId=${transactionId}`,
    ResendInstitutePhoneNoVerifyOtp: (transactionId) => `${API_URL}/otp/institute_phone_number_verify_otp_re_send?transactionId=${transactionId}`,
    ResendIndividualPhoneNoVerifyOtp: (transactionId) => `${API_URL}/otp/individual_phone_number_verify_otp_re_send?transactionId=${transactionId}`,

    //blog categories top navigation menu api
    GetTopBlogCategoryData:`${API_URL}/backend/blog-category/public/list-count`,

    //Media Coverage API
    GetMediaCoverageMenu: `${API_URL}/app/get/media`,

    //User Stories API
    CreateUserStories: `${API_URL}/story/create`,

    // URL thumbnail APIs
    UrlScrapping: `${ML_URL}/users/ogscrapper`,

    //ML Face authentication
    UserImageRegistration: `${ML_URL}/users/user-image-registration`,
    GetUserVerificationStatus: (userId) => `${ML_URL}/users/user-image-verification-status?userId=${userId}`,

    //Evolve pricing data API
    EvolvePricingData:(data)=> `${API_URL}/subscription/product/public/list?status=${data?.status}`,
    DevelopConfigdata: `${API_URL}/subscription/config/public/data`,

    
    //Subscription query API
    SubscriptionQuerySubmitData: (productType) => `${API_URL}/subscription/query/create/${productType === "DEVELOP" ? "develop" : "evolve"}`,

    GetDevelopSubsriptionList:(data)=> `${API_URL}/product/develop/public/list?instituteId=${data?.userId}&page=${data?.page}&size=${data?.size}`,
    GetEvolveSubsriptionList: (data)=> `${API_URL}/product/evolve/public/list?instituteId=${data?.userId}&page=${data?.page}&size=${data?.size}`,
    ApplyTrialSubsription: `${API_URL}/trial/subscription/apply`,
    GetSubscriptionConfigDataByNewspickId: (newsepickId) => `${API_URL}/subscription/config/current/data?newsepickId=${newsepickId}`,

    //Invoice API
    DownloadInvoice: (transactionId) => `${API_URL}/pdf/invoice/generate?transactionId=${transactionId}`,

    //SAVED ARTICLES API
    GetSavedItemData: (data) => `${ML_URL}/users/user-bookmark-details?userId=${data?.userId}&page=${data?.page}&size=${data?.size}`,

    //Saved Publications API
    GetSavedPublicationsData: (data) => `${ML_URL}/users/user-saved-publication?userId=${data?.userId}&page=${data?.page}&size=${data?.size}`,

    //ReportAbuse
    SubmitReportAbuseData: `${ML_URL}/users/user-report-abuse`,

    //Student Class API
    GetStudentClassData : `${API_URL}/studentClass/get-enable-list`,

    //news Deck Api 
    GetNewsDeckCategoryData: `${ML_URL}/users/news-deck-category`,
    GetNewsDechCategoryDetails : `${ML_URL}/users/news-deck-category-details`,
    GetDeckEligibilityByUserId: (data) => `${ML_URL}/users/deck-eligibilty?userId=${data?.userId}&eventType=${data?.eventType}`,

    //Candidate Register API
    GetCandidateConfigBySlug: (slug) => `${API_URL}/candidate/register/get-by-slug?slug=${slug}`,
    AddCandidateRegister: `${API_URL}/candidate/register/add`,

    //LeaderBoard Api 
    GetLeaderBoardData: (data) => `${ML_URL}/users/leaderboard?instituteId=${data?.instituteId}&page=${data?.page}&size=${data?.size}`,

    GetUserDetailsByPhoneNumber:(phone) => `${API_URL}/user/user-detail-by-mobile?mobile=${phone}`,

    /* Customize Quiz categoty list */
    GetQuizCategoryList: (userId) => `${API_URL}/recommend/recommended/quiz-category-list/?userId=${userId}`,
    GetQuizAudioEligibility: (userId) => `${API_URL}/recommend/recommended/check-audio-eligibilty/?userId=${userId}`,
    PostCategoryFilter: `${API_URL}/recommend/users/category-filter`,
    UpdateQuizAudioEligibility: `${API_URL}/recommend/recommended/audio-update/`,
    GetCustomizeQuizEligibility: `${API_URL}/recommend/users/quiz-customization-user-status`,

    /* Read More Similar news  */
    GetMoreSimilarNews:  `${API_URL}/recommend/recommended/similar-article/`,

    // GetTopNewsAudio Api
    GetTopNewsAudio: `${API_URL}/recommend/recommended/latest-news-audio/`,
    GetQuizPopupAudio: `${API_URL}/recommend/recommended/quiz-popup-audio/`,

    // Initilize User category 
    SetInitializeUserCategoty: (userId) =>  `${API_URL}/recommend/recommended/user-initialize-quiz-category/?userId=${userId}`,
};
export { API_URL, Apis };
