import { combineReducers } from "redux";
import latestReducer from "./LatestNews";
import trendingReducer from "./TrendingNewsReducers";
import {quizReducer } from "./QuizDataReducers";
import { quizQuesReducer } from "./QuizQuesReducers";
import { userDataReducer } from "./UserDataReducers";
import { filterReducer } from "./FilterReducers";

const rootReducer = combineReducers({
  trending: trendingReducer,
  latest: latestReducer,
  quizlist: quizReducer,
  quizQuesList: quizQuesReducer,
  userDatailsData: userDataReducer,
  filterCountList: filterReducer
});

export default rootReducer;
